import './App.css'

// Import React dependencies.
import React, { useCallback, useMemo, useState } from 'react'
// Import the Slate editor factory.
import { createEditor, Editor, Node } from 'slate'

// Import the Slate components and React plugin.
import { Slate, Editable, withReact, RenderLeafProps, RenderElementProps } from 'slate-react'
import { Element as SlateElement } from "slate"

import EditorLeaf from './components/EditorLeaf'
import EditorElement from './components/EditorElement'

// Slate types
import { Descendant } from 'slate'

// Textlint
import { TextlintKernel } from '@textlint/kernel'
import TextlintPluginMarkdown from '@textlint/textlint-plugin-markdown'
// @ts-ignore
import TextlintRuleNoTodo from 'textlint-rule-no-todo'

// @ts-ignore
const kernel = new TextlintKernel()
const options = {
    filePath: "/path/to/file.md",
    ext: ".md",
    plugins: [
        {
            pluginId: "text",
            plugin: TextlintPluginMarkdown
        }
    ],
    rules: [
        {
            ruleId: "no-todo",
            rule: TextlintRuleNoTodo
        }
    ]
};

kernel.lintText("TODO: text", options).then(result => {
    console.log(result.messages)
})

const App = () => {

    const [initialValue] = useState<Descendant[]>(
        [
            {
                type: 'paragraph',
                children: [
                    {
                        type: 'sentence', children: [
                            { text: 'X' }
                        ],
                        flesch: null,
                        lastText: null
                    }],
            }
        ]
    )

    const withSentences = (editor: Editor) => {
        const { isInline, isVoid } = editor

        const customElementBehavior: { [key: string]: { [key: string]: boolean; } } = {
            "paragraph": { isInline: false, isVoid: false },
            "sentence": { isInline: false, isVoid: false }
        }
        editor.isInline = (element: SlateElement) => {
            if (element.type in customElementBehavior) {
                return customElementBehavior[element.type]['isInline']
            } else {
                return isInline(element)
            }
        }

        editor.isVoid = (element: SlateElement) => {
            if (element.type in customElementBehavior) {
                return customElementBehavior[element.type]['isVoid']
            } else {
                return isVoid(element)
            }
        }

        return editor
    }

    const editor = useMemo(
        () => withSentences(withReact(createEditor())),
        []
    )

    const renderElement = useCallback((props: RenderElementProps) => <EditorElement {...props}
                                                                                    children={props.children}/>, [])
    const renderLeaf = useCallback((props: RenderLeafProps) => <EditorLeaf {...props} children={props.children}/>, [])

    return (
        <div className="app">
            <Slate
                editor={editor}
                initialValue={initialValue}
                onChange={async changedParagraphs => {
                    // see https://docs.slatejs.org/walkthroughs/06-saving-to-a-database
                    const isAstChange = editor.operations.some(
                        op => 'set_selection' !== op.type
                    )
                    if (isAstChange) {

                        for (const paragraph of changedParagraphs){
                            console.log("CHANGE")

                            if('children' in paragraph){
                                for(const sentence of paragraph.children){
                                    console.log(sentence)
                                    const textlintResult = await kernel.lintText(Node.string(sentence), options)

                                    console.log(textlintResult.messages)
                                }

                            }
                        }
                    }
                }}
            >
                <Editable
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                />
            </Slate>
        </div>
    )
}

export default App
